export const CARD_DEFAULT_BLACK = 'CARD-TAGLME-NTAG213-BLACK';
export const CARD_DEFAULT_WHITE = 'CARD-TAGLME-NTAG213-WHITE';
export const CARD_CUSTOM = 'CARD-NTAG213-CUSTOM';

export const STICKER_DEFAULT_22MM = 'STICKER-TAGLME-NTAG213-AM-3M-BBL-22';
export const STICKER_DEFAULT_30MM = 'STICKER-TAGLME-NTAG213-AM-3M-BBL-30';
export const STICKER_CUSTOM_22MM = 'STICKER-CUSTOM-NTAG213-AM-BBL-22';
export const STICKER_CUSTOM_30MM = 'STICKER-CUSTOM-NTAG213-AM-BBL-30';

export const API_URL = 'https://api.nfcpoint.ru';

export const CALCULATE_REQUEST = 'CALCULATE_REQUEST';
export const CALCULATE_SUCCESS = 'CALCULATE_SUCCESS';

export const CREATE_CART_REQUEST = 'CREATE_CART_REQUEST';
export const CREATE_CART_FAIL = 'CREATE_CART_FAIL';
export const CREATE_CART_SUCCESS = 'CREATE_CART_SUCCESS';

export const GET_SUBSCRIPTIONS_REQUEST = 'GET_SUBSCRIPTIONS_REQUEST';
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';